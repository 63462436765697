import * as React from 'react';
import { Link } from 'gatsby';

import './404.scss';

const NotFoundPage = () => (
  <main className="main-404">
    <title>Not found</title>
    <h1 className="h-404">Page not found</h1>
    <p className="p-404">
      Sorry{' '}
      <span role="img" aria-label="Pensive emoji">
        😔
      </span>{' '}
      we couldn’t find what you were looking for.
      <br />
      {process.env.NODE_ENV === 'development' ? (
        <>
          <br />
          Try creating a page in <code className="code-404">src/pages/</code>.
          <br />
        </>
      ) : null}
      <br />
      <Link to="/">Go home</Link>.
    </p>
  </main>
);

export default NotFoundPage;
